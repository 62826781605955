const colors = {
    // alertSuccessBg: '#d4edda',
    // alertSuccess: '#155724',
    alertSuccessBg: '#fce7b2',
    alertSuccess: '#856404',
    alertWarningBg: '#fce7b2',
    alertWarning: '#856404',
    black: '#201335',
    cursorBorder: 'red',
    danger: '#ff5252',
    dark: '#0c0c0c',
    given: 'yellow',
    gold: '#cd853f',
    gray: '#aaaaaa',
    guess: 'orange',
    light: '#fce7b2',
    lightgray: '#f8f4f4',
    medium: '#6e6969',
    primary: '#4f4789',
    reveal: '#b65fcf',
    right: 'greenyellow',
    secondary: '#ffb17a',
    white: '#fffded',
    wrong: 'red',
};

export default colors;
