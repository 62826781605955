const colors = [
    { value: 1, label: 'aliceblue', color: 'aliceblue' },
    { value: 2, label: 'antiquewhite', color: 'antiquewhite' },
    { value: 3, label: 'aqua', color: 'aqua' },
    { value: 4, label: 'aquamarine', color: 'aquamarine' },
    { value: 5, label: 'azure', color: 'azure' },
    { value: 6, label: 'beige', color: 'beige' },
    { value: 7, label: 'bisque', color: 'bisque' },
    { value: 8, label: 'black', color: 'black' },
    { value: 9, label: 'blanchedalmond', color: 'blanchedalmond' },
    { value: 10, label: 'blue', color: 'blue' },
    { value: 11, label: 'blueviolet', color: 'blueviolet' },
    { value: 12, label: 'brown', color: 'brown' },
    { value: 13, label: 'burlywood', color: 'burlywood' },
    { value: 14, label: 'cadetblue', color: 'cadetblue' },
    { value: 15, label: 'chartreuse', color: 'chartreuse' },
    { value: 16, label: 'chocolate', color: 'chocolate' },
    { value: 17, label: 'coral', color: 'coral' },
    { value: 18, label: 'cornflowerblue', color: 'cornflowerblue' },
    { value: 19, label: 'cornsilk', color: 'cornsilk' },
    { value: 20, label: 'crimson', color: 'crimson' },
    { value: 21, label: 'cyan', color: 'cyan' },
    { value: 22, label: 'darkblue', color: 'darkblue' },
    { value: 23, label: 'darkcyan', color: 'darkcyan' },
    { value: 24, label: 'darkgoldenrod', color: 'darkgoldenrod' },
    { value: 25, label: 'darkgray', color: 'darkgray' },
    { value: 26, label: 'darkgreen', color: 'darkgreen' },
    { value: 27, label: 'darkgrey', color: 'darkgrey' },
    { value: 28, label: 'darkkhaki', color: 'darkkhaki' },
    { value: 29, label: 'darkmagenta', color: 'darkmagenta' },
    { value: 30, label: 'darkolivegreen', color: 'darkolivegreen' },
    { value: 31, label: 'darkorange', color: 'darkorange' },
    { value: 32, label: 'darkorchid', color: 'darkorchid' },
    { value: 33, label: 'darkred', color: 'darkred' },
    { value: 34, label: 'darksalmon', color: 'darksalmon' },
    { value: 35, label: 'darkseagreen', color: 'darkseagreen' },
    { value: 36, label: 'darkslateblue', color: 'darkslateblue' },
    { value: 37, label: 'darkslategrey', color: 'darkslategrey' },
    { value: 38, label: 'darkturquoise', color: 'darkturquoise' },
    { value: 39, label: 'darkviolet', color: 'darkviolet' },
    { value: 40, label: 'deeppink', color: 'deeppink' },
    { value: 41, label: 'deepskyblue', color: 'deepskyblue' },
    { value: 42, label: 'dimgray', color: 'dimgray' },
    { value: 43, label: 'dimgrey', color: 'dimgrey' },
    { value: 44, label: 'dodgerblue', color: 'dodgerblue' },
    { value: 45, label: 'firebrick', color: 'firebrick' },
    { value: 46, label: 'floralwhite', color: 'floralwhite' },
    { value: 47, label: 'forestgreen', color: 'forestgreen' },
    { value: 48, label: 'fuchsia', color: 'fuchsia' },
    { value: 49, label: 'gainsboro', color: 'gainsboro' },
    { value: 50, label: 'ghostwhite', color: 'ghostwhite' },
    { value: 51, label: 'gold', color: 'gold' },
    { value: 52, label: 'goldenrod', color: 'goldenrod' },
    { value: 53, label: 'gray', color: 'gray' },
    { value: 54, label: 'green', color: 'green' },
    { value: 55, label: 'greenyellow', color: 'greenyellow' },
    { value: 56, label: 'grey', color: 'grey' },
    { value: 57, label: 'honeydew', color: 'honeydew' },
    { value: 58, label: 'hotpink', color: 'hotpink' },
    { value: 59, label: 'indianred', color: 'indianred' },
    { value: 60, label: 'indigo', color: 'indigo' },
    { value: 61, label: 'ivory', color: 'ivory' },
    { value: 62, label: 'khaki', color: 'khaki' },
    { value: 63, label: 'lavender', color: 'lavender' },
    { value: 64, label: 'lavenderblush', color: 'lavenderblush' },
    { value: 65, label: 'lawngreen', color: 'lawngreen' },
    { value: 66, label: 'lemonchiffon', color: 'lemonchiffon' },
    { value: 67, label: 'lightblue', color: 'lightblue' },
    { value: 68, label: 'lightcoral', color: 'lightcoral' },
    { value: 69, label: 'lightcyan', color: 'lightcyan' },
    { value: 70, label: 'lightgoldenrodyellow', color: 'lightgoldenrodyellow' },
    { value: 71, label: 'lightgray', color: 'lightgray' },
    { value: 72, label: 'lightgreen', color: 'lightgreen' },
    { value: 73, label: 'lightgrey', color: 'lightgrey' },
    { value: 74, label: 'lightpink', color: 'lightpink' },
    { value: 75, label: 'lightsalmon', color: 'lightsalmon' },
    { value: 76, label: 'lightseagreen', color: 'lightseagreen' },
    { value: 77, label: 'lightskyblue', color: 'lightskyblue' },
    { value: 78, label: 'lightslategrey', color: 'lightslategrey' },
    { value: 79, label: 'lightsteelblue', color: 'lightsteelblue' },
    { value: 80, label: 'lightyellow', color: 'lightyellow' },
    { value: 81, label: 'lime', color: 'lime' },
    { value: 82, label: 'limegreen', color: 'limegreen' },
    { value: 83, label: 'linen', color: 'linen' },
    { value: 84, label: 'magenta', color: 'magenta' },
    { value: 85, label: 'maroon', color: 'maroon' },
    { value: 86, label: 'mediumaquamarine', color: 'mediumaquamarine' },
    { value: 87, label: 'mediumblue', color: 'mediumblue' },
    { value: 88, label: 'mediumorchid', color: 'mediumorchid' },
    { value: 89, label: 'mediumpurple', color: 'mediumpurple' },
    { value: 90, label: 'mediumseagreen', color: 'mediumseagreen' },
    { value: 91, label: 'mediumslateblue', color: 'mediumslateblue' },
    { value: 92, label: 'mediumspringgreen', color: 'mediumspringgreen' },
    { value: 93, label: 'mediumturquoise', color: 'mediumturquoise' },
    { value: 94, label: 'mediumvioletred', color: 'mediumvioletred' },
    { value: 95, label: 'midnightblue', color: 'midnightblue' },
    { value: 96, label: 'mintcream', color: 'mintcream' },
    { value: 97, label: 'mistyrose', color: 'mistyrose' },
    { value: 98, label: 'moccasin', color: 'moccasin' },
    { value: 99, label: 'navajowhite', color: 'navajowhite' },
    { value: 100, label: 'navy', color: 'navy' },
    { value: 101, label: 'oldlace', color: 'oldlace' },
    { value: 102, label: 'olive', color: 'olive' },
    { value: 103, label: 'olivedrab', color: 'olivedrab' },
    { value: 104, label: 'orange', color: 'orange' },
    { value: 105, label: 'orangered', color: 'orangered' },
    { value: 106, label: 'orchid', color: 'orchid' },
    { value: 107, label: 'palegoldenrod', color: 'palegoldenrod' },
    { value: 108, label: 'palegreen', color: 'palegreen' },
    { value: 109, label: 'paleturquoise', color: 'paleturquoise' },
    { value: 110, label: 'palevioletred', color: 'palevioletred' },
    { value: 111, label: 'papayawhip', color: 'papayawhip' },
    { value: 112, label: 'peachpuff', color: 'peachpuff' },
    { value: 113, label: 'peru', color: 'peru' },
    { value: 114, label: 'pink', color: 'pink' },
    { value: 115, label: 'plum', color: 'plum' },
    { value: 116, label: 'powderblue', color: 'powderblue' },
    { value: 117, label: 'purple', color: 'purple' },
    { value: 118, label: 'rebeccapurple', color: 'rebeccapurple' },
    { value: 119, label: 'red', color: 'red' },
    { value: 120, label: 'rosybrown', color: 'rosybrown' },
    { value: 121, label: 'royalblue', color: 'royalblue' },
    { value: 122, label: 'saddlebrown', color: 'saddlebrown' },
    { value: 123, label: 'salmon', color: 'salmon' },
    { value: 124, label: 'sandybrown', color: 'sandybrown' },
    { value: 125, label: 'seagreen', color: 'seagreen' },
    { value: 126, label: 'seashell', color: 'seashell' },
    { value: 127, label: 'sienna', color: 'sienna' },
    { value: 128, label: 'silver', color: 'silver' },
    { value: 129, label: 'skyblue', color: 'skyblue' },
    { value: 130, label: 'slateblue', color: 'slateblue' },
    { value: 131, label: 'slategray', color: 'slategray' },
    { value: 132, label: 'snow', color: 'snow' },
    { value: 133, label: 'springgreen', color: 'springgreen' },
    { value: 134, label: 'steelblue', color: 'steelblue' },
    { value: 135, label: 'tan', color: 'tan' },
    { value: 136, label: 'teal', color: 'teal' },
    { value: 137, label: 'thistle', color: 'thistle' },
    { value: 138, label: 'tomato', color: 'tomato' },
    { value: 139, label: 'turquoise', color: 'turquoise' },
    { value: 140, label: 'violet', color: 'violet' },
    { value: 141, label: 'wheat', color: 'wheat' },
    { value: 142, label: 'white', color: 'white' },
    { value: 143, label: 'whitesmoke', color: 'whitesmoke' },
    { value: 144, label: 'yellow', color: 'yellow' },
    { value: 145, label: 'yellowgreen', color: 'yellowgreen' },
];

export default colors;
