const icons = [
    { value: 6, icon: 'access-point', label: 'access-point' },
    { value: 7, icon: 'account', label: 'account' },
    { value: 8, icon: 'account-question', label: 'account-question' },
    { value: 16, icon: 'airballoon', label: 'airballoon' },
    { value: 17, icon: 'airplane', label: 'airplane' },
    { value: 19, icon: 'alarm', label: 'alarm' },
    { value: 21, icon: 'alert', label: 'alert' },
    { value: 22, icon: 'alien', label: 'alien' },
    { value: 38, icon: 'anchor', label: 'anchor' },
    { value: 39, icon: 'android', label: 'android' },
    { value: 46, icon: 'antenna', label: 'antenna' },
    { value: 79, icon: 'asterisk', label: 'asterisk' },
    { value: 84, icon: 'auto-fix', label: 'auto-fix' },
    { value: 98, icon: 'badminton', label: 'badminton' },
    { value: 100, icon: 'bag-checked', label: 'bag-checked' },
    { value: 101, icon: 'bag-personal', label: 'bag-personal' },
    { value: 103, icon: 'balloon', label: 'balloon' },
    { value: 107, icon: 'bank', label: 'bank' },
    { value: 110, icon: 'barn', label: 'barn' },
    { value: 112, icon: 'baseball', label: 'baseball' },
    { value: 114, icon: 'basket', label: 'basket' },
    { value: 116, icon: 'beach', label: 'beach' },
    { value: 118, icon: 'bed', label: 'bed' },
    { value: 119, icon: 'bee', label: 'bee' },
    { value: 120, icon: 'bell', label: 'bell' },
    { value: 123, icon: 'bicycle', label: 'bicycle' },
    { value: 124, icon: 'bike', label: 'bike' },
    { value: 132, icon: 'blank', label: 'blank' },
    { value: 151, icon: 'border-color', label: 'border-color' },
    { value: 170, icon: 'bridge', label: 'bridge' },
    { value: 171, icon: 'briefcase', label: 'briefcase' },
    { value: 181, icon: 'broom', label: 'broom' },
    { value: 182, icon: 'brush', label: 'brush' },
    { value: 183, icon: 'bucket', label: 'bucket' },
    { value: 187, icon: 'bug', label: 'bug' },
    { value: 190, icon: 'bullhorn', label: 'bullhorn' },
    { value: 191, icon: 'bullseye', label: 'bullseye' },
    { value: 194, icon: 'bus', label: 'bus' },
    { value: 197, icon: 'cactus', label: 'cactus' },
    { value: 198, icon: 'cake', label: 'cake' },
    { value: 199, icon: 'calculator', label: 'calculator' },
    { value: 207, icon: 'camera', label: 'camera' },
    { value: 210, icon: 'candle', label: 'candle' },
    { value: 214, icon: 'car', label: 'car' },
    { value: 219, icon: 'cat', label: 'cat' },
    { value: 225, icon: 'certificate', label: 'certificate' },
    { value: 246, icon: 'chat', label: 'chat' },
    { value: 251, icon: 'chess-bishop', label: 'chess-bishop' },
    { value: 252, icon: 'chess-king', label: 'chess-king' },
    { value: 253, icon: 'chess-knight', label: 'chess-knight' },
    { value: 254, icon: 'chess-pawn', label: 'chess-pawn' },
    { value: 255, icon: 'chess-queen', label: 'chess-queen' },
    { value: 256, icon: 'chess-rook', label: 'chess-rook' },
    { value: 275, icon: 'church', label: 'church' },
    { value: 276, icon: 'cigar', label: 'cigar' },
    { value: 279, icon: 'city', label: 'city' },
    { value: 282, icon: 'clock', label: 'clock' },
    { value: 289, icon: 'code-braces', label: 'code-braces' },
    { value: 290, icon: 'code-brackets', label: 'code-brackets' },
    { value: 300, icon: 'coffee', label: 'coffee' },
    { value: 302, icon: 'cog', label: 'cog' },
    { value: 307, icon: 'comment', label: 'comment' },
    { value: 309, icon: 'compass', label: 'compass' },
    { value: 323, icon: 'cookie', label: 'cookie' },
    { value: 330, icon: 'cow', label: 'cow' },
    { value: 334, icon: 'creation', label: 'creation' },
    { value: 340, icon: 'crown', label: 'crown' },
    { value: 342, icon: 'crystal-ball', label: 'crystal-ball' },
    { value: 343, icon: 'cube', label: 'cube' },
    { value: 372, icon: 'database', label: 'database' },
    { value: 373, icon: 'death-star', label: 'death-star' },
    { value: 393, icon: 'dice-1', label: 'dice-1' },
    { value: 394, icon: 'dice-2', label: 'dice-2' },
    { value: 395, icon: 'dice-3', label: 'dice-3' },
    { value: 396, icon: 'dice-4', label: 'dice-4' },
    { value: 397, icon: 'dice-5', label: 'dice-5' },
    { value: 398, icon: 'dice-6', label: 'dice-6' },
    { value: 424, icon: 'dna', label: 'dna' },
    { value: 433, icon: 'dog', label: 'dog' },
    { value: 448, icon: 'draw', label: 'draw' },
    { value: 453, icon: 'duck', label: 'duck' },
    { value: 454, icon: 'dumbbell', label: 'dumbbell' },
    { value: 455, icon: 'dump-truck', label: 'dump-truck' },
    { value: 457, icon: 'earth', label: 'earth' },
    { value: 463, icon: 'elephant', label: 'elephant' },
    { value: 471, icon: 'emoticon', label: 'emoticon' },
    { value: 492, icon: 'fan', label: 'fan' },
    { value: 507, icon: 'fire', label: 'fire' },
    { value: 508, icon: 'fish', label: 'fish' },
    { value: 510, icon: 'flag', label: 'flag' },
    { value: 512, icon: 'flash', label: 'flash' },
    { value: 522, icon: 'flower', label: 'flower' },
    { value: 527, icon: 'food', label: 'food' },
    { value: 528, icon: 'foot-print', label: 'foot-print' },
    { value: 529, icon: 'football', label: 'football' },
    { value: 613, icon: 'fruit-cherries', label: 'fruit-cherries' },
    { value: 615, icon: 'fruit-grapes', label: 'fruit-grapes' },
    { value: 616, icon: 'fruit-pineapple', label: 'fruit-pineapple' },
    { value: 617, icon: 'fruit-watermelon', label: 'fruit-watermelon' },
    { value: 640, icon: 'ghost', label: 'ghost' },
    { value: 643, icon: 'glass-cocktail', label: 'glass-cocktail' },
    { value: 649, icon: 'glass-wine', label: 'glass-wine' },
    { value: 655, icon: 'go-kart', label: 'go-kart' },
    { value: 658, icon: 'golf', label: 'golf' },
    { value: 668, icon: 'grid', label: 'grid' },
    { value: 669, icon: 'grill', label: 'grill' },
    { value: 671, icon: 'guitar-acoustic', label: 'guitar-acoustic' },
    { value: 672, icon: 'guitar-electric', label: 'guitar-electric' },
    { value: 677, icon: 'halloween', label: 'halloween' },
    { value: 688, icon: 'heart', label: 'heart' },
    { value: 689, icon: 'helicopter', label: 'helicopter' },
    { value: 690, icon: 'help', label: 'help' },
    { value: 696, icon: 'hiking', label: 'hiking' },
    { value: 699, icon: 'hockey-puck', label: 'hockey-puck' },
    { value: 702, icon: 'home', label: 'home' },
    { value: 707, icon: 'horseshoe', label: 'horseshoe' },
    { value: 719, icon: 'ice-cream', label: 'ice-cream' },
    { value: 720, icon: 'ice-pop', label: 'ice-pop' },
    { value: 729, icon: 'infinity', label: 'infinity' },
    { value: 740, icon: 'jeepney', label: 'jeepney' },
    { value: 751, icon: 'key', label: 'key' },
    { value: 759, icon: 'ladybug', label: 'ladybug' },
    { value: 761, icon: 'lamp', label: 'lamp' },
    { value: 772, icon: 'lead-pencil', label: 'lead-pencil' },
    { value: 786, icon: 'lifebuoy', label: 'lifebuoy' },
    { value: 788, icon: 'lightbulb', label: 'lightbulb' },
    { value: 799, icon: 'lock', label: 'lock' },
    { value: 823, icon: 'matrix', label: 'matrix' },
    { value: 824, icon: 'medal', label: 'medal' },
    { value: 825, icon: 'medical-bag', label: 'medical-bag' },
    { value: 869, icon: 'mushroom', label: 'mushroom' },
    { value: 870, icon: 'music', label: 'music' },
    { value: 890, icon: 'noodles', label: 'noodles' },
    { value: 892, icon: 'note', label: 'note' },
    { value: 897, icon: 'numeric', label: 'numeric' },
    { value: 930, icon: 'owl', label: 'owl' },
    { value: 946, icon: 'paper-cut-vertical', label: 'paper-cut-vertical' },
    { value: 948, icon: 'paperclip', label: 'paperclip' },
    { value: 957, icon: 'paw', label: 'paw' },
    { value: 959, icon: 'peace', label: 'peace' },
    { value: 961, icon: 'pen', label: 'pen' },
    { value: 967, icon: 'phone', label: 'phone' },
    { value: 979, icon: 'police-badge', label: 'police-badge' },
    { value: 980, icon: 'poll', label: 'poll' },
    { value: 988, icon: 'power', label: 'power' },
    { value: 1020, icon: 'racquetball', label: 'racquetball' },
    { value: 1035, icon: 'recycle', label: 'recycle' },
    { value: 1100, icon: 'rodent', label: 'rodent' },
    { value: 1101, icon: 'roller-skate', label: 'roller-skate' },
    { value: 1102, icon: 'rollerblade', label: 'rollerblade' },
    { value: 1125, icon: 'run', label: 'run' },
    { value: 1129, icon: 'sail-boat', label: 'sail-boat' },
    { value: 1154, icon: 'seal', label: 'seal' },
    { value: 1157, icon: 'security', label: 'security' },
    { value: 1178, icon: 'sheep', label: 'sheep' },
    { value: 1183, icon: 'shoe-print', label: 'shoe-print' },
    { value: 1184, icon: 'shopping', label: 'shopping' },
    { value: 1195, icon: 'signal', label: 'signal' },
    { value: 1219, icon: 'sleep', label: 'sleep' },
    { value: 1229, icon: 'snowflake', label: 'snowflake' },
    { value: 1231, icon: 'soccer', label: 'soccer' },
    { value: 1255, icon: 'spray', label: 'spray' },
    { value: 1358, icon: 'train', label: 'train' },
    { value: 1359, icon: 'tram', label: 'tram' },
    { value: 1379, icon: 'truck', label: 'truck' },
    { value: 1380, icon: 'trumpet', label: 'trumpet' },
    { value: 1381, icon: 'tshirt-crew', label: 'tshirt-crew' },
    { value: 1382, icon: 'tshirt-v', label: 'tshirt-v' },
    { value: 1396, icon: 'umbrella', label: 'umbrella' },
    { value: 1411, icon: 'van-passenger', label: 'van-passenger' },
    { value: 1412, icon: 'van-utility', label: 'van-utility' },
    { value: 1461, icon: 'virus', label: 'virus' },
    { value: 1466, icon: 'volleyball', label: 'volleyball' },
    { value: 1467, icon: 'volume-high', label: 'volume-high' },
    { value: 1481, icon: 'walk', label: 'walk' },
    { value: 1489, icon: 'wave', label: 'wave' },
    { value: 1542, icon: 'yoga', label: 'yoga' },
    { value: 1549, icon: 'zodiac-aquarius', label: 'zodiac-aquarius' },
    { value: 1550, icon: 'zodiac-aries', label: 'zodiac-aries' },
    { value: 1551, icon: 'zodiac-cancer', label: 'zodiac-cancer' },
    { value: 1552, icon: 'zodiac-capricorn', label: 'zodiac-capricorn' },
    { value: 1553, icon: 'zodiac-gemini', label: 'zodiac-gemini' },
    { value: 1554, icon: 'zodiac-leo', label: 'zodiac-leo' },
    { value: 1555, icon: 'zodiac-libra', label: 'zodiac-libra' },
    { value: 1556, icon: 'zodiac-pisces', label: 'zodiac-pisces' },
    { value: 1557, icon: 'zodiac-sagittarius', label: 'zodiac-sagittarius' },
    { value: 1558, icon: 'zodiac-scorpio', label: 'zodiac-scorpio' },
    { value: 1559, icon: 'zodiac-taurus', label: 'zodiac-taurus' },
    { value: 1560, icon: 'zodiac-virgo', label: 'zodiac-virgo' },
];

export default icons;
