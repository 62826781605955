import React, { Component } from 'react';

class Credits extends Component {

    render() {
        return (
            <div>
                <h1>Credits</h1>
                <p>Copyright © 2021 GooglyPower Software</p>
            </div>
        );
    }
}

export default Credits;
